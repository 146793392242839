import Cookies from "js-cookie";

export default (signupUrl, serviceId, deal, intent) => ({
    // PARAMETERS
    signupUrl: signupUrl,
    deal: deal ? deal : null,
    serviceId: serviceId ? serviceId : 1,
    intent: intent ? intent : 'mowing',

    // ORDER FIELDS
    name: '',
    phone: '',
    address: {
        street: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    },

    // TRACKING FIELDS
    queryStringPassThroughString: '',
    url_referrer: null,
    landingpage: null,

    // ADDITIONAL FIELDS
    resumingOrder: false,
    pristine: null,
    isLoading: false,

    // GOOGLE AUTOCOMPLETE FIELDS
    formatted_address: '',
    place_chosen: false,
    googlePlaceId: null,
    order: {
        'locality': 3,
        'region': 4,
        'postal-code': 5,
    },
    isUSA: true,
    hasState: true,
    fullResultData: {},
    element: null,
    autocomplete: null,

    /**
     * Component Initialization
     */
    init() {
        this.isLoading = false;
        // INIT GOOGLE MAPS
        this.element = this.$el.querySelector(':scope [x-ref="googleAutocomplete"]');
        if (this.element === null) {
            console.error("Cannot find Google Places Autocomplete input [x-ref=\"googleAutocomplete\"]");
            return;
        }
        this.addressInputInit();

        // INIT FORM VALIDATION
        this.pristine = new window.Pristine(this.$refs.form);

        // WATCH MODELS
        this.$watch('formatted_address', () => { this.removeError(this.$refs.googleAutocomplete) });
        this.$watch('name', () => { this.removeError(this.$refs.nameInput) });
        this.$watch('phone', () => { this.removeError(this.$refs.phoneInput) });

        // PARSE QUERY STRING
        // this also writes url parameters to cookies for re-use
        this.parseQuerystring();

        // SET / TRACK LANDING PAGE
        this.setLandingPageCookie();
        this.landingpage = this.getLandingPage();

        // SET / TRACK REFERRER
        this.setUrlReferrerCookie();
        this.url_referrer = this.getUrlReferrer();

        // RETRIEVE DATA FROM COOKIES
        if (Cookies) {

            try {
                var name = JSON.parse(Cookies.get('ls.saved_order_module.name'));
                if (!name) {
                    // try name parameter deal if no order name found
                    name = JSON.parse(Cookies.get('www_name'));
                }
                this.name = name;
            }
            catch (error) {
                // console.debug(error);
            }

            try {
                var phone = JSON.parse(Cookies.get('ls.saved_order_module.phone'));
                if (!phone) {
                    // try url parameter phone if no order phone found
                    phone = JSON.parse(Cookies.get('www_phone'));
                }
                this.phone = phone;
            }
            catch (error) {
                // console.debug(error);
            }

            try {
                var deal = JSON.parse(Cookies.get('ls.saved_order_module.deal'));
                if (this.deal == null || this.deal == undefined) {
                    this.deal = deal;
                }
            }
            catch (error) {
                // console.debug(error);
            }

            try {
                var deal = JSON.parse(Cookies.get('www_deal'));
                if (this.deal == null || this.deal == undefined) {
                    this.deal = deal;
                }
            }
            catch (error) {
                // console.debug(error);
            }

            try {
                const address = Cookies.get('ls.saved_order_module.address');
                if (address) {
                    // parse address
                    const parsedAddress = JSON.parse(address);
                    if (String(parsedAddress.street).length > 0
                        && String(parsedAddress.city).length > 0
                        && String(parsedAddress.state).length > 0
                        && String(parsedAddress.zip).length > 0) {
                        this.formatted_address = parsedAddress.street + ', ' + parsedAddress.city + ', ' + parsedAddress.state + ' ' + parsedAddress.zip;
                        this.address = parsedAddress;
                        this.place_chosen = true;
                        this.googlePlaceId = parsedAddress.googlePlaceId;
                    }
                }
            }
            catch (error) {
                // console.debug(error);
            }
        }

        // RETRIEVE DATA FROM QUERY PARAMETERS
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        name = urlParams.get('name')
        if(name) {
            this.name = name;
        }
        phone = urlParams.get('phone')
        if(phone) {
            this.phone = phone;
        }
    },

    checkResumeSignup() {
        // switch to resume order if cookie inputs are valid
        // doing this on next tick prevents this from getting stuck
        this.isLoading = false;
        this.$nextTick(() => {
            if (this.formatted_address && this.validate()) {
                this.resumingOrder = true;
                this._lsTS({name: 'Customer Resume Signup Viewed'});
            }
            else {
                // if(this.$refs.googleAutocomplete) {
                //     this.$refs.googleAutocomplete.focus();
                // }
            }
        });
    },

    /**
     * Form Functions
     * @return true iff form is valid and ready to submit
     */
    validate() {
        var isValid = true;
        this.pristine.validate();

        // check address
        if (!this.place_chosen) {
            var errorMessage = 'Choose an address or type it manually for better results.';
            if (this.formatted_address == '') {
                errorMessage = 'Please enter your address.';
            }
            this.addError(this.$refs.googleAutocomplete, errorMessage);
            if (isValid) {
                this.$refs.googleAutocomplete.focus();
            }
            isValid = false;
        }
        else {
            this.removeError(this.$refs.googleAutocomplete);
        }

        // check name
        const nameError = this.getError(this.$refs.nameInput);
        if (nameError !== undefined) {
            this.addError(this.$refs.nameInput, 'Please enter your name.');
            if (isValid) {
                this.$refs.nameInput.focus();
            }
            isValid = false;
            return isValid;
        }
        else {
            this.removeError(this.$refs.nameInput);
        }

        // check phone
        const phoneError = this.getError(this.$refs.phoneInput);
        if (phoneError !== undefined) {
            this.addError(this.$refs.phoneInput, 'Please enter a valid phone number.');
            if (isValid) {
                this.$refs.phoneInput.focus();
            }
            isValid = false;
        }
        else {
            this.removeError(this.$refs.phoneInput);
        }

        return isValid;

    },

    submit() {
        // already submitting
        if (this.isLoading) {
            return;
        }

        // validate form
        var valid = this.validate()
        if (!valid) {
            this.resumingOrder = false;
            return;
        }

        // show loading indicator
        this.isLoading = true;

        // redirect the user to signup
        var location = this.signupUrl +
            '?' +
            (this.formatted_address ? 'address=' + encodeURIComponent(this.formatted_address) : '') +
            (this.name ? '&name=' + encodeURIComponent(this.name) : '') +
            (this.phone ? '&phone=' + encodeURIComponent(this.phone) : '') +
            (this.url_referrer ? '&url_referrer=' + encodeURIComponent(this.url_referrer) : '') +
            (this.landingpage ? '&landingpage=' + encodeURIComponent(this.landingpage) : '') +
            ((this.serviceId <= 1 || this.intent == 'treatment') ? '&lead=0' : '&lead=1') +
            (this.intent ? '&intent=' + encodeURIComponent(this.intent) : '') +
            (this.deal ? '&deal=' + encodeURIComponent(this.deal) : '') +
            (this.place_chosen ? '&googlePlace=true' : '') +
            (this.googlePlaceId ? '&googlePlaceId=' + encodeURIComponent(this.googlePlaceId) : '') +
            (window.lsSegmentAnonId ? '&segid=' + encodeURIComponent(window.lsSegmentAnonId) : '') +
            this.queryStringPassThroughString;

        // SET COOKIES
        try {
            // SET RESUME COOKIES
            Cookies.set('ls.saved_order_module.address', JSON.stringify({
                street: this.address.street,
                city: this.address.city,
                state: this.address.state,
                zip: this.address.zip,
                googlePlaceId: this.googlePlaceId,
            }));
            Cookies.set('ls.saved_order_module.name', JSON.stringify(this.name));
            Cookies.set('ls.saved_order_module.phone', JSON.stringify(this.phone));
        } catch(error) {
            console.log(error);
        }

        // SUBMIT TRACKING
        try {
            // track submit event
            var eventTarget = this.resumingOrder ? 'signup-form--resume-order-button' : 'signup-form--submitting-form';
            this._lsTS({
                target: eventTarget,
                name: 'submitting',
                data: { value: location }
            });

            // Call google conversion function defined resources/views/components/signup/signup.blade.php
            if (typeof window.google_trackConversion === 'function') {
                window.goog_report_conversion(location);
            }
        }
        catch(error) {
            console.error(error);
        }

        // REDIRECT THE USER
        window.location = location;
    },

    /**
     * Address Input Functions
     */
    addressResetData() {
        this.address = {
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        };
    },
    addressInputInit() {
        if (window.google && window.google.maps && window.google.maps.places) {
            this.autocomplete = new window.google.maps.places.Autocomplete(this.element, {
                types: ['address']
            });
            window.google.maps.event.addListener(this.autocomplete, 'place_changed', () => this.addressHandleResponse(this.autocomplete.getPlace()));
        }
        else {
            document.addEventListener('googleplaces:init', () => {
                this.addressInputInit();
            });
        }
    },
    addressInputHasSelectableOptions() {
        return document.getElementsByClassName('pac-item')[0] !== undefined;
    },
    addressInputFocussed() {
        this.$refs.googleAutocomplete.setAttribute('autocomplete', 'do-not-autofill');
        if (this.formatted_address !== '' && this.addressInputHasSelectableOptions()) {
            this.removeError(this.$refs.googleAutocomplete);
        }
    },
    addressInputBlurred() {
        if (this.formatted_address != '' && !this.place_chosen) {
            var errorMessage = 'Choose an address or type it manually for better results.';
            if (!this.addressInputHasSelectableOptions()) {
                errorMessage = 'Address not recognized. If auto-filled, try typing it manually.';
            }
            this.addError(this.$refs.googleAutocomplete, errorMessage);
        }
    },
    addressHandleResponse(placeResultData) {
    
        // handle empty place result by not processing
        if(placeResultData == undefined) {
            return;
        }

        var streetNumberPrefix = '';
        try {
            var addressInputValue = this.formatted_address;
            var inputStreetNumber = addressInputValue.split(' ', 1)[0];
            var placeResultStreetNumber = placeResultData.formatted_address.split(' ', 1)[0];
            var streetNumberPrefix = (placeResultStreetNumber.startsWith(inputStreetNumber)) ? '' : inputStreetNumber + ' ';
        }
        catch(e) {
            // handle unexpected issues gracefully
            console.warn(e);
        }

        // process result
        this.fullResultData = placeResultData;
        this.addressResetData();

        // extract place data
        this.formatted_address = streetNumberPrefix + placeResultData.formatted_address;
        const addressFragment = document.createRange().createContextualFragment(placeResultData.adr_address);
        this.address.street = (streetNumberPrefix != undefined ? streetNumberPrefix : '') + (addressFragment.querySelector(".street-address") ? addressFragment.querySelector(".street-address").innerHTML : '');
        this.address.address2 = addressFragment.querySelector(".extended-address") ? addressFragment.querySelector(".extended-address").innerHTML : '';
        this.address.city = addressFragment.querySelector(".locality") ? addressFragment.querySelector(".locality").innerHTML : '';
        this.address.state = addressFragment.querySelector(".region") ? addressFragment.querySelector(".region").innerHTML : '';
        this.address.zip = addressFragment.querySelector(".postal-code") ? addressFragment.querySelector(".postal-code").innerHTML : '';
        this.address.country = addressFragment.querySelector(".country-name") ? addressFragment.querySelector(".country-name").innerHTML : '';
        this.isUSA = ['united states', 'usa', 'us', 'united states of america'].indexOf(this.address.country.toLowerCase()) !== -1;
        this.hasState = this.address.state !== '';

        // mark place as chosen
        this.place_chosen = true;
        this.googlePlaceId = placeResultData.place_id;
        this.$dispatch('place-chosen', { address: this.address, resultResponse: this.fullResultData });


        // focus input element once visible on next tick
        this.$nextTick(() => {
            var nameInput = this.$refs.nameInput;
            if (nameInput) {
                this.removeError(this.$refs.googleAutocomplete);
                nameInput.focus();
            }
        });
    },

    /**
     * Tracking Functions
     */
    _lsTS(event) {
        var appName = 'www';
        var view = 'signup-alpine-v1-launch';
        var data = event.data || {};
        data.appName = appName;
        data.view = view;
        data.target = event.target;

        var trackEvent = event.name || 'unknown';

        if (window.analytics && typeof (window.analytics.track) === 'function') {
            window.analytics.track(trackEvent, data);
        }
    },

    /**
     * Error Helper Functions
     */
    getError(ref) {
        var refErrors = this.pristine.getErrors(ref);
        if (refErrors && refErrors.length >= 0) {
            return refErrors[0];
        }
        return undefined;
    },
    addError(ref, message) {

        if (ref.offsetParent === null) {
            // do not add error if element is not visible
            return;
        }

        var tippyInstance = ref._tippy;
        if (tippyInstance === undefined) {
            tippyInstance = tippy(ref, {
                content: message,
                hideOnClick: false,
                trigger: 'manual',
                placement: 'bottom-start',
                theme: 'error',
                offset: [0, 16],
                onClickOutside(instance, event) {
                    instance.destroy();
                },
            });
        }
        tippyInstance.show();
    },
    removeError(ref) {
        var tippyInstance = ref._tippy;
        if (tippyInstance !== undefined) {
            tippyInstance.destroy();
        }
    },

    /**
     * Context Helper Functions
     * reads context from url parameters and cookies
     */
    parseQuerystring() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const keys = urlParams.keys();
        var qsObj = {};

        for (const key of keys) {
            const value = urlParams.get(key);

            if (key !== 'promo' && key !== 'deal') {
                var wwwKey = 'www_' + key;
                if (Cookies) {
                    if (Cookies && Cookies.get(wwwKey)) {
                        Cookies.remove(wwwKey);
                    }
                    Cookies.set(wwwKey, value, { expires: 30 });
                }
            }
            if (key !== 'name' && key !== 'phone' && key !== 'address' && key !== 'intent') {
                this.queryStringPassThroughString += '&' + key + '=' + value;
            }
        }
    },
    getUrlReferrer() {
        var url_referrer;

        if (Cookies && Cookies.get('www_url_referrer')) {
            url_referrer = Cookies.get('www_url_referrer');
        } else {
            // use current document.lcation as referrer when submitting orders
            url_referrer = document.location;
        }

        return url_referrer;
    },
    setUrlReferrerCookie() {
        // https pages do not pass through a url_referrer automatically
        // if one already exists, use it; otherwise, manually set the url_referrer to the current location
        var currentReferrer = (document.referrer) ? document.referrer : document.location.href;
        var refHostname = currentReferrer.split('/')[2];
        var domain = window.location.hostname

        if (refHostname.indexOf(domain) > -1) {
            // only perform this check if it is lawnstarter.com || www.lawnstarter.com
            // if there is no current www_url_referrer cookie set one
            if (Cookies && Cookies.get('www_url_referrer')) {
                Cookies.set('www_url_referrer', currentReferrer, { expires: 30 });
            }
        } else {
            // if not lawnstarter, and cookie exists remove old cookie and replace with new referrer
            if (Cookies) {
                if (Cookies.get('www_url_referrer')) {
                    Cookies.remove('www_url_referrer');
                }

                Cookies.set('www_url_referrer', currentReferrer, { expires: 30 });
            }
        }
    },
    getLandingPage() {
        var landingpage = (Cookies && Cookies.get('www_landingpage')) ?
            Cookies.get('www_landingpage') :
            document.location.hostname + document.location.pathname;
        return landingpage;
    },
    setLandingPageCookie() {
        if (Cookies && !Cookies.get('www_landingpage')) {
            var hostname = window.location.hostname
            var pathname = window.location.pathname;
            var landingpage = (pathname) ? hostname + pathname : hostname;
            Cookies.set('www_landingpage', landingpage, { expires: 30 });
        }
    }

});
